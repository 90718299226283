import { get as fetchGet } from '../../lib/async-helpers';

export default function getCompanyInfo(set, get) {
  return async (companyId) => {
    try {
      const response = await fetch(`/api/company/${companyId}`, fetchGet);
      const json = await response.json();
      set({
        user: {
          ...get().user,
          company: json?.resultSet,
        },
      });
    } catch (e) {
      console.error(`An error has occurred: ${e.message}`);
      set({
        error: e,
      });
    }
  };
}
