import { get as fetchGet } from '../../lib/async-helpers';

export default function getCompanies(set) {
  return async () => {
    set({ isLoadingCompanies: true });
    try {
      const response = await fetch('/api/company/', fetchGet);
      const json = await response.json();
      set({
        isLoadingCompanies: false,
        companies: json?.resultSet,
        error: json?.error,
      });
    } catch (e) {
      console.error(`An error has occurred: ${e.message}`);
      set({
        error: e,
        isLoadingCompanies: false,
      });
    }
  };
}
