import { postWithBody } from '../../lib/async-helpers';

export default function createEmployeeAndBusinessProfiles(set, get) {
  return async (email, onboarding) => {
    try {
      const response = await fetch(`/api/users/onboarding/${email}`, postWithBody(onboarding));
      const json = await response.json();
      set({
        user: {
          ...get().user,
          onboardingProfilesCreated: json?.success,
        },
      });
    } catch (e) {
      console.error(`An error has occurred: ${e.message}`);
      set({
        user: {
          ...get().user,
          onboardingError: e.message,
          onboarding: undefined,
        },
      });
    }
  };
}
