import getUser from './handlers/getUser';
import { get as fetchGet } from '../../lib/async-helpers';

export default function getSignedInUser(set, get) {
  return async () => {
    try {
      const session = await fetch('/api/session/user');
      const json = await session.json();
      // if user email has been found in session, verify if has been onborded
      let hasOnboarded;
      let hasProfile;
      let preferredLanguage;
      let isAuthorized;
      let companyId;
      let companyType;
      let isPlatformAdmin;
      let businessLineId;
      let userName;
      let userLastName;
      let userTitle;
      let companyName;
      let userId;
      if (json?.user?.email) {
        const userEmail = json.user.email;
        const user = await getUser(userEmail);
        hasProfile = user.rows.length > 0;
        const response = await fetch(`/api/verify/${userEmail}`, fetchGet);
        const verifyJsonResponse = await response.json();
        preferredLanguage = verifyJsonResponse?.preferredLanguage;
        isAuthorized = verifyJsonResponse?.isAuthorized;
        companyId = verifyJsonResponse?.companyId;
        companyName = verifyJsonResponse?.companyName;
        companyType = verifyJsonResponse?.companyType;
        isPlatformAdmin = verifyJsonResponse?.isPlatformAdmin;
        businessLineId = verifyJsonResponse?.businessLineId;
        hasOnboarded = verifyJsonResponse?.hasOnboarded;
        userName = user?.rows[0]?.name;
        userLastName = user?.rows[0]?.last_name;
        userTitle = user?.rows[0]?.title;
        userId = verifyJsonResponse.userId;
      }

      set({
        user: {
          ...get().user,
          email: json?.user?.email,
          hasFetchedFromSession: true,
          isSessionValid: json?.user?.isSessionValid,
          isAuthorized,
          hasOnboarded,
          hasProfile,
          preferredLanguage,
          companyId,
          companyType,
          isPlatformAdmin,
          businessLineId,
          userName,
          userLastName,
          userTitle,
          companyName,
          userId,
        },
      });
    } catch (e) {
      console.error(`Failed to retrieve user data ${e}`);
      set({ error: e.message });
    }
  };
}
