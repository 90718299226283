import { patchWithBody, get as fetchGet } from '../../lib/async-helpers';
import getUserHandler from './handlers/getUser';

export default function logInUserWithGoogle(set, get) {
  return async ({ email }, cb) => {
    try {
      const response = await fetch(`/api/google-login?email=${email}`);

      const json = await response.json();

      // if user email has been found in session, verify if has been onborded
      let hasProfile = false;
      let hasOnboarded = get().user?.hasOnboarded;
      if (!get().user?.hasOnboarded) {
        const userAccessId = get().user?.userId;
        const onboardedResponse = await fetch(`/api/users/${email}/onboard`, patchWithBody({ userAccessId }));
        const onboardedJson = await onboardedResponse.json();
        hasOnboarded = onboardedJson?.success ?? false;
      }

      if (json?.user?.email) {
        const user = await getUserHandler(json?.user?.email);
        hasProfile = user.rows?.length > 0;
      }

      // fetch information on user's company and business line for session cookie
      const userInfo = await fetch(`/api/verify/${email}`, fetchGet);
      const userInfoJson = await userInfo.json();

      // save user info in session cookie
      const userCompanyId = userInfoJson?.companyId;
      const userBusinessLineId = userInfoJson?.businessLineId;

      // save to session cookie
      await fetch('/api/session/patch', patchWithBody({
        userCompanyId,
        userBusinessLineId,
      }));

      set({
        user: {
          ...get().user,
          email,
          hasProfile,
          hasOnboarded,
          isSessionValid: true,
        },
      });
      cb();
    } catch (e) {
      console.error(`An error has occurred while trying to login: ${e.message}`);
      set({ error: e.message });
    }
  };
}
