import { get as fetchGet } from '../../lib/async-helpers';

export default function logInUserWithMagic(set, get) {
  return async (email) => {
    set({ isLoading: true });

    try {
      const response = await fetch(`/api/verify/${email}`, fetchGet);
      const json = await response.json();

      set({
        user: {
          ...get().user,
          userId: json?.userId,
          isAuthorized: json?.isAuthorized,
          preferredLanguage: json?.preferredLanguage,
          companyId: json?.companyId,
          companyType: json?.companyType,
          isPlatformAdmin: json?.isPlatformAdmin,
          businessLineId: json?.businessLineId,
          hasOnboarded: json?.hasOnboarded,
        },
        isLoading: false,
      });
    } catch (e) {
      console.error(`An error has occurred while trying to login: ${e.message}`);
      set({ error: e.message, isLoading: false });
    }
  };
}
