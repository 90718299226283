export default function setGuestUser(set) {
  return async (userDetails) => {
    const result = await fetch('/api/users/user-access/guest@oko.trade');
    const json = await result?.json();
    if (json.rows?.length) {
      const { id, email } = json.rows[0];
      set({
        user: {
          userId: id,
          email,
          userName: 'Guest',
          userLastName: 'User',
          companyId: userDetails?.companyId,
          businessLineId: userDetails?.businessLineId,
          isAuthorized: true,
          isSessionValid: true,
          hasProfile: true,
        },
      });
    }
  };
}
