import { postWithBody } from '../../lib/async-helpers';

export default function getSearchTransactionsResult(set) {
  return async ({ userCompanyId, userBusinessLineId }, key, limit, cb) => {
    try {
      const response = await fetch('/api/transactions/search', postWithBody({
        userCompanyId, userBusinessLineId, key, limit,
      }));

      const json = await response.json();
      const recentSearches = localStorage.getItem('recent-searches');
      if (recentSearches) {
        const searchArray = recentSearches.split(',');

        if (!searchArray.includes(key)) {
          searchArray.push(key);
        }
        if (searchArray.length > 5) {
          searchArray.shift();
        }
        localStorage.setItem('recent-searches', searchArray.join(','));
      } else {
        localStorage.setItem('recent-searches', `${key}`);
      }

      cb(json?.resultSet);
    } catch (error) {
      cb([]);
      set({ error: error.message });
    }
  };
}
