const headers = {
  'Content-Type': 'application/json',
};

export const patch = {
  method: 'PATCH',
  headers,
};

export const post = {
  method: 'POST',
  headers,
};

export const put = {
  method: 'PUT',
  headers,
};

export const get = {
  method: 'GET',
  headers,
};

export const deleteHttp = {
  method: 'DELETE',
  headers,
};

export const postWithBody = (body) => ({ ...post, body: JSON.stringify(body) });

export const putWithBody = (body) => ({ ...put, body: JSON.stringify(body) });

export const patchWithBody = (body) => ({ ...patch, body: JSON.stringify(body) });

export const postWithAuthorization = (token, body) => ({
  ...post,
  headers: {
    ...post.headers,
    Authorization: `Bearer ${token}`,
  },
  ...(body && { body: JSON.stringify(body) }),
});

export const getWithAuthorization = (token) => ({
  ...get,
  headers: {
    ...get.headers,
    Authorization: `Bearer ${token}`,
  },
});
