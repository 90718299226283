export default function saveEmployeeInfo(set, get) {
  return (employeeBody) => {
    set({
      user: {
        ...get().user,
        onboarding: {
          employeeForm: employeeBody,
        },
      },
    });
  };
}
