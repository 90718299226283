import { get as fetchGet } from '../../lib/async-helpers';

export default function getDashboardColumnsByUser(set, get) {
  return async (userId) => {
    try {
      const response = await fetch(`/api/users/dashboard?userId=${userId}`, fetchGet);
      const json = await response.json();
      set({
        dashboardColumnsUser: json?.resultSet,
      });
    } catch (e) {
      console.error(`An error has occurred: ${e.message}`);
      set({
        error: e
      });
    }
  };
}