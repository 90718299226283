import { get as fecthGet } from '../../lib/async-helpers';

function getBusinessLine(set, get) {
  return async (companyId, businessLineId) => {
    try {
      const response = await fetch(
        `/api/company/businessLine?companyId=${companyId}&businessLineId=${businessLineId}`,
        fecthGet,
      );
      const json = await response.json();
      set({ businessLines: json?.resultSet });
    } catch (e) {
      set({ error: e.message });
    }
  };
}

export default getBusinessLine;
